<template>
  <v-navigation-drawer
    v-model="show"
    right
    app
    disable-resize-watcher
    color="secondary"
    width="420"
    class="px-2 pt-2 d-flex"
    style="z-index: 12"
  >
    <v-row
      align="center"
      no-gutters
    >
      <v-col
        cols="12"
        class="my-1"
      >
        <v-dialog
          v-model="showFeedbackDialog"
          width="576"
        >
          <template v-slot:activator="{ on: onDialog }">
            <v-btn
              block
              :color="feedback.color"
              v-on="onDialog"
            >
              <v-icon left>
                {{ feedback.icon }}
              </v-icon>
              {{ feedback.title }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title
              class="text-h6 grey lighten-2"
              primary-title
            >
              {{ feedback.title }}
            </v-card-title>

            <v-card-text
              class="black--text pt-5"
            >
              <v-row>
                <v-col cols="12">
                  <span>What do you think of us?</span>
                  <v-radio-group
                    v-model="feedback.feeling"
                    class="xx-large mb-4"
                    hide-details
                    dense
                    row
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-radio
                          on-icon="mdi-emoticon-angry"
                          off-icon="mdi-emoticon-angry"
                          color="red"
                          :value="1"
                          v-on="on"
                        />
                      </template>
                      <span>Hate</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-radio
                          on-icon="mdi-emoticon-confused"
                          off-icon="mdi-emoticon-confused"
                          color="orange"
                          :value="2"
                          v-on="on"
                        />
                      </template>
                      <span>Dislike</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-radio
                          on-icon="mdi-emoticon-neutral"
                          off-icon="mdi-emoticon-neutral"
                          color="amber"
                          :value="3"
                          v-on="on"
                        />
                      </template>
                      <span>Indifferent</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-radio
                          on-icon="mdi-emoticon-happy"
                          off-icon="mdi-emoticon-happy"
                          color="light-green"
                          :value="4"
                          v-on="on"
                        />
                      </template>
                      <span>Like</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-radio
                          on-icon="mdi-emoticon-excited"
                          off-icon="mdi-emoticon-excited"
                          color="green"
                          :value="5"
                          v-on="on"
                        />
                      </template>
                      <span>Love</span>
                    </v-tooltip>
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-textarea
                    v-model="feedback.description"
                    outlined
                    dense
                    auto-grow
                    name="description"
                    label="What would you like to share with us?"
                  />
                  <span>How likely are you to recommendability us to your friends and collegues?</span>
                  <v-radio-group
                    v-model="feedback.recommendability"
                    class="xx-large mb-4"
                    hide-details
                    row
                  >
                    <v-radio
                      v-for="n in 11"
                      :key="n"
                      :on-icon="`mdi-numeric-${n-1}-circle`"
                      :off-icon="`mdi-numeric-${n-1}-circle`"
                      :value="n-1"
                      :color="redGreenGradient11[n-1].color"
                    />
                  </v-radio-group>
                  <v-row>
                    <v-col class="py-0">
                      <span class="caption">Not very likely</span>
                    </v-col>
                    <v-spacer />
                    <v-col class="py-0 text-right">
                      <span class="caption text-right">Very likely</span>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                @click="showFeedbackDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="success"
                :disabled="feedback.feeling === null"
                :loading="false"
                @click="submitFeedback"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row
      align="center"
      no-gutters
    >
      <v-col
        cols="12"
        class="my-1"
      >
        <v-btn
          block
          :color="help.color"
          @click="helpArticles"
        >
          <v-icon left>
            {{ help.icon }}
          </v-icon>
          {{ help.title }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      align="center"
      no-gutters
    >
      <v-col
        cols="12"
        class="my-1"
      >
        <v-dialog
          v-model="showBugDialog"
          width="800"
        >
          <template v-slot:activator="{ on: onDialog }">
            <v-btn
              block
              :color="bug.color"
              v-on="onDialog"
            >
              <v-icon left>
                {{ bug.icon }}
              </v-icon>
              {{ bug.title }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title
              class="text-h6 grey lighten-2"
              primary-title
            >
              {{ bug.title }}
            </v-card-title>
            <v-card-text
              class="black--text pt-5 pb-0"
            >
              <p
                class="grey--text font-italic"
              >
                In order for us to better understand the issue, please report the bug on the web page where you encountered it.
              </p>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="bug.description"
                    outlined
                    dense
                    auto-grow
                    label="What happened?"
                  />
                  <v-textarea
                    v-model="bug.stepsToReproduce"
                    outlined
                    dense
                    auto-grow
                    label="Steps to reproduce:"
                  />
                  <v-textarea
                    v-model="bug.expectedResult"
                    outlined
                    dense
                    auto-grow
                    label="Expected result:"
                  />
                  <v-textarea
                    v-model="bug.actualResult"
                    outlined
                    dense
                    auto-grow
                    label="Actual result:"
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                @click="showBugDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="success"
                @click="submitBug"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <iframe
      src="https://webchat.botframework.com/embed/noww-kb-bot?s=vjnIYd0oTbI.N18rsQR7bYO8GlS-4oQCLARVB0e8ZHUpn0RkmEnSOHI"
      :style="{ minHeight: (windowMixin_windowHeight - 44 * 3 - 16) + 'px', minWidth: 404 + 'px'}"
    />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { windowMixin } from '@/shared/mixins/general'
import { tenantService } from '@/shared/services'
import { greenRedGradientLight8 } from '@/shared/constants'

export default {
  name: 'CoreHelpMenu',

  mixins: [windowMixin],

  data () {
    return {
      showFeedbackDialog: false,
      showBugDialog: false,
      feedback:
        {
          title: 'Provide Feedback',
          icon: 'mdi-comment-quote',
          color: 'success',
          feeling: null,
          description: null,
          recommendability: null
        },
      help:
        {
          title: 'Help Articles',
          icon: 'mdi-account-question',
          color: 'info'
        },
      bug:
        {
          title: 'Report a Bug',
          icon: 'mdi-bug',
          color: 'error',
          url: null,
          description: null,
          stepsToReproduce: null,
          expectedResult: null,
          actualResult: null,
          browser: null,
          viewportWidth: null,
          viewportHeight: null,
          azScreenshotLocation: null
        }
    }
  },

  computed: {
    ...mapState(['chatDrawer']),
    show: {
      get () {
        return this.chatDrawer
      },
      set (value) {
        this.setChatDrawer(value)
      }
    },
    redGreenGradient11 () {
      return [
        greenRedGradientLight8[7],
        greenRedGradientLight8[7],
        greenRedGradientLight8[6],
        greenRedGradientLight8[5],
        greenRedGradientLight8[4],
        greenRedGradientLight8[4],
        greenRedGradientLight8[3],
        greenRedGradientLight8[2],
        greenRedGradientLight8[1],
        greenRedGradientLight8[0],
        greenRedGradientLight8[0]
      ]
    }
  },

  methods: {
    ...mapMutations(['setChatDrawer']),
    helpArticles () {
      this.show = false
      this.$router.push({ name: 'HelpArticle' })
    },
    async submitFeedback () {
      await tenantService.provideFeedback(this.feedback)
      this.showFeedbackDialog = false
      this.$store.commit('setGlobalSnackbar', {
        message: 'Thank you for your feedback!',
        color: 'success'
      })
      this.feedback.feeling = null
      this.feedback.description = null
      this.feedback.recommendability = null
    },
    async submitBug () {
      this.bug.url = window.location.href
      this.bug.browser = navigator.userAgent
      this.bug.viewportWidth = window.innerWidth
      this.bug.viewportHeight = window.innerHeight
      await tenantService.reportBug(this.bug)
      this.showBugDialog = false
      this.$store.commit('setGlobalSnackbar', {
        message: 'Bug report submitted!',
        color: 'success'
      })
      this.bug.description = null
      this.bug.stepsToReproduce = null
      this.bug.expectedResult = null
      this.bug.actualResult = null
    }
  }
}
</script>

<style lang="scss">
.xx-large i.v-icon.mdi {
  font-size: xx-large !important;
}
</style>
