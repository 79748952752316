var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      staticClass: "px-2 pt-2 d-flex",
      staticStyle: { "z-index": "12" },
      attrs: {
        right: "",
        app: "",
        "disable-resize-watcher": "",
        color: "secondary",
        width: "420"
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-row",
        { attrs: { align: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "my-1", attrs: { cols: "12" } },
            [
              _c(
                "v-dialog",
                {
                  attrs: { width: "576" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var onDialog = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: { block: "", color: _vm.feedback.color }
                              },
                              onDialog
                            ),
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.feedback.icon) +
                                    "\n            "
                                )
                              ]),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.feedback.title) +
                                  "\n          "
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.showFeedbackDialog,
                    callback: function($$v) {
                      _vm.showFeedbackDialog = $$v
                    },
                    expression: "showFeedbackDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "text-h6 grey lighten-2",
                          attrs: { "primary-title": "" }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.feedback.title) +
                              "\n          "
                          )
                        ]
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "black--text pt-5" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("span", [
                                    _vm._v("What do you think of us?")
                                  ]),
                                  _c(
                                    "v-radio-group",
                                    {
                                      staticClass: "xx-large mb-4",
                                      attrs: {
                                        "hide-details": "",
                                        dense: "",
                                        row: ""
                                      },
                                      model: {
                                        value: _vm.feedback.feeling,
                                        callback: function($$v) {
                                          _vm.$set(_vm.feedback, "feeling", $$v)
                                        },
                                        expression: "feedback.feeling"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-radio",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          "on-icon":
                                                            "mdi-emoticon-angry",
                                                          "off-icon":
                                                            "mdi-emoticon-angry",
                                                          color: "red",
                                                          value: 1
                                                        }
                                                      },
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [_c("span", [_vm._v("Hate")])]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-radio",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          "on-icon":
                                                            "mdi-emoticon-confused",
                                                          "off-icon":
                                                            "mdi-emoticon-confused",
                                                          color: "orange",
                                                          value: 2
                                                        }
                                                      },
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [_c("span", [_vm._v("Dislike")])]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-radio",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          "on-icon":
                                                            "mdi-emoticon-neutral",
                                                          "off-icon":
                                                            "mdi-emoticon-neutral",
                                                          color: "amber",
                                                          value: 3
                                                        }
                                                      },
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [_c("span", [_vm._v("Indifferent")])]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-radio",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          "on-icon":
                                                            "mdi-emoticon-happy",
                                                          "off-icon":
                                                            "mdi-emoticon-happy",
                                                          color: "light-green",
                                                          value: 4
                                                        }
                                                      },
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [_c("span", [_vm._v("Like")])]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-radio",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          "on-icon":
                                                            "mdi-emoticon-excited",
                                                          "off-icon":
                                                            "mdi-emoticon-excited",
                                                          color: "green",
                                                          value: 5
                                                        }
                                                      },
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [_c("span", [_vm._v("Love")])]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "auto-grow": "",
                                      name: "description",
                                      label:
                                        "What would you like to share with us?"
                                    },
                                    model: {
                                      value: _vm.feedback.description,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.feedback,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "feedback.description"
                                    }
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      "How likely are you to recommendability us to your friends and collegues?"
                                    )
                                  ]),
                                  _c(
                                    "v-radio-group",
                                    {
                                      staticClass: "xx-large mb-4",
                                      attrs: { "hide-details": "", row: "" },
                                      model: {
                                        value: _vm.feedback.recommendability,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.feedback,
                                            "recommendability",
                                            $$v
                                          )
                                        },
                                        expression: "feedback.recommendability"
                                      }
                                    },
                                    _vm._l(11, function(n) {
                                      return _c("v-radio", {
                                        key: n,
                                        attrs: {
                                          "on-icon":
                                            "mdi-numeric-" +
                                            (n - 1) +
                                            "-circle",
                                          "off-icon":
                                            "mdi-numeric-" +
                                            (n - 1) +
                                            "-circle",
                                          value: n - 1,
                                          color:
                                            _vm.redGreenGradient11[n - 1].color
                                        }
                                      })
                                    }),
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { staticClass: "py-0" }, [
                                        _c("span", { staticClass: "caption" }, [
                                          _vm._v("Not very likely")
                                        ])
                                      ]),
                                      _c("v-spacer"),
                                      _c(
                                        "v-col",
                                        { staticClass: "py-0 text-right" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "caption text-right"
                                            },
                                            [_vm._v("Very likely")]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.showFeedbackDialog = false
                                }
                              }
                            },
                            [_vm._v("\n              Cancel\n            ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "success",
                                disabled: _vm.feedback.feeling === null,
                                loading: false
                              },
                              on: { click: _vm.submitFeedback }
                            },
                            [_vm._v("\n              Submit\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "my-1", attrs: { cols: "12" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { block: "", color: _vm.help.color },
                  on: { click: _vm.helpArticles }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.help.icon) + "\n        "
                    )
                  ]),
                  _vm._v("\n        " + _vm._s(_vm.help.title) + "\n      ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "my-1", attrs: { cols: "12" } },
            [
              _c(
                "v-dialog",
                {
                  attrs: { width: "800" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var onDialog = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              { attrs: { block: "", color: _vm.bug.color } },
                              onDialog
                            ),
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.bug.icon) +
                                    "\n            "
                                )
                              ]),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.bug.title) +
                                  "\n          "
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.showBugDialog,
                    callback: function($$v) {
                      _vm.showBugDialog = $$v
                    },
                    expression: "showBugDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "text-h6 grey lighten-2",
                          attrs: { "primary-title": "" }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.bug.title) +
                              "\n          "
                          )
                        ]
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "black--text pt-5 pb-0" },
                        [
                          _c("p", { staticClass: "grey--text font-italic" }, [
                            _vm._v(
                              "\n              In order for us to better understand the issue, please report the bug on the web page where you encountered it.\n            "
                            )
                          ]),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "auto-grow": "",
                                      label: "What happened?"
                                    },
                                    model: {
                                      value: _vm.bug.description,
                                      callback: function($$v) {
                                        _vm.$set(_vm.bug, "description", $$v)
                                      },
                                      expression: "bug.description"
                                    }
                                  }),
                                  _c("v-textarea", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "auto-grow": "",
                                      label: "Steps to reproduce:"
                                    },
                                    model: {
                                      value: _vm.bug.stepsToReproduce,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.bug,
                                          "stepsToReproduce",
                                          $$v
                                        )
                                      },
                                      expression: "bug.stepsToReproduce"
                                    }
                                  }),
                                  _c("v-textarea", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "auto-grow": "",
                                      label: "Expected result:"
                                    },
                                    model: {
                                      value: _vm.bug.expectedResult,
                                      callback: function($$v) {
                                        _vm.$set(_vm.bug, "expectedResult", $$v)
                                      },
                                      expression: "bug.expectedResult"
                                    }
                                  }),
                                  _c("v-textarea", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "auto-grow": "",
                                      label: "Actual result:"
                                    },
                                    model: {
                                      value: _vm.bug.actualResult,
                                      callback: function($$v) {
                                        _vm.$set(_vm.bug, "actualResult", $$v)
                                      },
                                      expression: "bug.actualResult"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.showBugDialog = false
                                }
                              }
                            },
                            [_vm._v("\n              Cancel\n            ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "success" },
                              on: { click: _vm.submitBug }
                            },
                            [_vm._v("\n              Submit\n            ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("iframe", {
        style: {
          minHeight: _vm.windowMixin_windowHeight - 44 * 3 - 16 + "px",
          minWidth: 404 + "px"
        },
        attrs: {
          src:
            "https://webchat.botframework.com/embed/noww-kb-bot?s=vjnIYd0oTbI.N18rsQR7bYO8GlS-4oQCLARVB0e8ZHUpn0RkmEnSOHI"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }